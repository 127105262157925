













































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import {mapGetters,mapActions} from 'vuex'
export default Vue.extend({
  components: {
    Layout
  },
  computed: {
    ...mapGetters("azJobApplicantsModule", {
      jobApplicant: "GET_JOB_APPLICANT"
    }),
  },
  watch: {
      jobApplicant: {
          deep: true,
          handler(val) {
            if(val) {
                this.applicant = Object.assign({}, val)
            }
          }
      }
  },
  created(){
      this.fetchJobApplicant(this.$route.params.id)
  },
  methods:{
    ...mapActions("azJobApplicantsModule",{
      fetchJobApplicant:"FETCH_JOB_APPLICANT",
      updateJobApplicant:"UPDATE_JOB_APPLICANT",
      downloadFile:"DOWNLOAD_FILE",
    }),
    getSearchValue(val){

      this.searchText=val
    },

    async updateApplicant() {
        const data = {
            id: this.applicant.id,
            jobApplicantStatus: this.applicant.jobApplicantStatus
        }
        this.loading = true
        await this.updateJobApplicant(data)
        this.loading = false
    },
  },
  data() {
    return {
      title: "Job Applicant",
      searchText:'',
      loading: false,
      applicant: null,
    };
  },
});
